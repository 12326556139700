import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../db/connection";

import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Graph() {
  const [hours, setHours] = useState();
  const [customers, setCustomers] = useState();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const getHours = async () => {
    const array = [];

    const query = await getDocs(collection(firestore, "CustomersAmount"));
    query.forEach((doc) => {
      // console.log(doc.id, " => ", doc.data());
      array.push(doc.data().hour);
    });

    return setHours(array);
  };

  const getCustomers = async () => {
    const array = [];

    const query = await getDocs(collection(firestore, "CustomersAmount"));
    query.forEach((doc) => {
      // console.log(doc.id, " => ", doc.data());
      array.push(doc.data().countCheckedInCustomer);
    });

    return setCustomers(array);
  };

  const data = {
    labels: hours,
    datasets: [
      {
        label: "Besucher",
        data: customers,
        borderColor: "rgb(175, 202, 51)",
        backgroundColor: "rgb(175, 202, 51, 0.5)",
        tension: 0.5,
      },
    ],
  };

  useEffect(() => {
    getHours();
    getCustomers();
  }, []);

  return (
    <>{hours !== undefined ? <Line options={options} data={data} /> : ""}</>
  );
}
