import { useEffect, useState } from "react";
import Graph from "./components/Graph";

function App() {
  const [checkinNumber, setCheckinNumber] = useState();
  const [loading, setLoading] = useState(true);

  const fetchJumpersData = () => {
    fetch(
      "https://jumpers-fitness.de/club-checkin-number/25/Jumpers.JumpersFitnessTld",
      {headers: {'Access-Control-Allow-Origin': '*'}}
    )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          setLoading(false);
          setCheckinNumber(result);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    fetchJumpersData();
  }, []);

  return (
    <>
      <header></header>
      <main
        className="
          h-screen
          flex flex-col justify-center items-center
          space-y-8
          "
      >
        <section>
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-2xl font-semibold">Studioauslastung</h2>
            <h3 className="text-lg text-neutral-700">Unterhaching</h3>
            <div className="text-2xl flex flex-row space-x-2">
              <span>
                {loading ? "Loading..." : checkinNumber.countCheckedInCustomer}
              </span>
              <span className="text-neutral-400">/</span>
              <span>
                {loading ? "Loading..." : checkinNumber.maxCheckinsAllowed}
              </span>
            </div>
          </div>
        </section>
        <section className="w-11/12 lg:w-1/2">
          <Graph />
        </section>
      </main>
      <footer></footer>
    </>
  );
}

export default App;
