import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC_8u8Vqw1dqd2QavLzjPZP8TUhF9Byrqw",
  authDomain: "jumpers-tracker.firebaseapp.com",
  projectId: "jumpers-tracker",
  storageBucket: "jumpers-tracker.appspot.com",
  messagingSenderId: "614622929162",
  appId: "1:614622929162:web:cdc66c7a95f7ea1194daf3",
};

export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
